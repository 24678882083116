import React from 'react';
import { Link } from 'react-router-dom';
import { SEO } from '../components/SEO';
import { COMPANY_INFO } from '../lib/constants';

export function TermsOfUse() {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <SEO 
        title="Terms of Use"
        description={`${COMPANY_INFO.NAME} terms of use. Read our terms and conditions for using our merchant finding service.`}
      />
      
      <div className="container mx-auto px-4 max-w-4xl">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Terms of Use</h1>
        
        <div className="bg-white rounded-lg shadow-md p-8 space-y-6">
          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">1. Agreement to Terms</h2>
            <p className="text-gray-600">
              By accessing and using MerchantFinder, you agree to be bound by these Terms of Use and all applicable 
              laws and regulations. If you do not agree with any of these terms, you are prohibited from using this site.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">2. Use License</h2>
            <div className="space-y-4">
              <p className="text-gray-600">Permission is granted to temporarily access MerchantFinder for personal, 
              non-commercial use. This license does not include:</p>
              <ul className="list-disc list-inside text-gray-600 space-y-2">
                <li>Modifying or copying materials</li>
                <li>Using materials for commercial purposes</li>
                <li>Attempting to reverse engineer any software</li>
                <li>Removing any copyright or proprietary notations</li>
              </ul>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">3. Disclaimer</h2>
            <p className="text-gray-600">
              The materials on MerchantFinder are provided on an 'as is' basis. We make no warranties, expressed or implied, 
              and hereby disclaim and negate all other warranties including, without limitation, implied warranties or 
              conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">4. Contact Information</h2>
            <p className="text-gray-600">
              For any questions about these Terms of Use, please contact us at:<br />
              Email: {COMPANY_INFO.EMAIL}<br />
              Phone: {COMPANY_INFO.PHONE}<br />
              Address: {COMPANY_INFO.ADDRESS.STREET}, {COMPANY_INFO.ADDRESS.CITY}, {COMPANY_INFO.ADDRESS.POSTCODE}
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}