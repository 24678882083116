import type { City, Merchant } from './types';

export const cities: City[] = [
  { name: 'London', slug: 'london' },
  { name: 'Birmingham', slug: 'birmingham' },
  { name: 'Manchester', slug: 'manchester' },
  { name: 'Liverpool', slug: 'liverpool' },
  { name: 'Leeds', slug: 'leeds' },
  { name: 'Sheffield', slug: 'sheffield' },
  { name: 'Teesside', slug: 'teesside' },
  { name: 'Bristol', slug: 'bristol' },
  { name: 'Bournemouth and Poole', slug: 'bournemouth-and-poole' },
  { name: 'Stoke-on-Trent', slug: 'stoke-on-trent' },
  { name: 'Leicester', slug: 'leicester' },
  { name: 'Wirral', slug: 'wirral' },
  { name: 'Coventry', slug: 'coventry' },
  { name: 'Nottingham', slug: 'nottingham' },
  { name: 'Bradford', slug: 'bradford' },
  { name: 'Newcastle', slug: 'newcastle' },
  { name: 'Bolton', slug: 'bolton' },
  { name: 'Brighton and Hove', slug: 'brighton-and-hove' },
  { name: 'Plymouth', slug: 'plymouth' },
  { name: 'Hull', slug: 'hull' },
  { name: 'Preston', slug: 'preston' },
  { name: 'Derby', slug: 'derby' },
  { name: 'Aldershot and Farnborough', slug: 'aldershot-and-farnborough' },
  { name: 'Southampton', slug: 'southampton' },
  { name: 'Wigan', slug: 'wigan' },
  { name: 'Barnsley', slug: 'barnsley' },
  { name: 'Portsmouth', slug: 'portsmouth' },
  { name: 'Luton', slug: 'luton' },
  { name: 'York', slug: 'york' },
  { name: 'Northampton', slug: 'northampton' },
  { name: 'Milton Keynes', slug: 'milton-keynes' },
  { name: 'Worthing', slug: 'worthing' },
  { name: 'Ipswich', slug: 'ipswich' },
  { name: 'Crawley', slug: 'crawley' },
  { name: 'Sunderland', slug: 'sunderland' },
  { name: 'Southend-on-Sea', slug: 'southend-on-sea' },
  { name: 'Rochdale', slug: 'rochdale' },
  { name: 'Warrington', slug: 'warrington' },
  { name: 'Mansfield', slug: 'mansfield' },
  { name: 'Swindon', slug: 'swindon' },
  { name: 'Reading', slug: 'reading' },
  { name: 'Blackburn', slug: 'blackburn' },
  { name: 'Burnley', slug: 'burnley' },
  { name: 'Huddersfield', slug: 'huddersfield' },
  { name: 'Oxford', slug: 'oxford' },
  { name: 'Wakefield', slug: 'wakefield' },
  { name: 'Blackpool', slug: 'blackpool' },
  { name: 'Norwich', slug: 'norwich' },
  { name: 'Grimsby', slug: 'grimsby' },
  { name: 'Telford', slug: 'telford' },
  { name: 'Peterborough', slug: 'peterborough' },
  { name: 'Gloucester', slug: 'gloucester' },
  { name: 'Cambridge', slug: 'cambridge' },
  { name: 'Doncaster', slug: 'doncaster' },
  { name: 'Hastings', slug: 'hastings' }
].sort((a, b) => a.name.localeCompare(b.name));

export const merchants: Merchant[] = [
  { name: 'Builders Merchants', slug: 'builders-merchants' },
  { name: 'Timber Merchants', slug: 'timber-merchants' },
  { name: 'Plumbers Merchants', slug: 'plumbers-merchants' },
  { name: 'Roofing Merchants', slug: 'roofing-merchants' },
  { name: 'Electrical Merchants', slug: 'electrical-merchants' }
].sort((a, b) => a.name.localeCompare(b.name));