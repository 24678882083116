import { useQuery } from '@tanstack/react-query';
import { searchMerchants } from '../api/merchants';
import merchantData from '../data/merchants.json';

export function useSearch(merchantType: string, location: string, page: number, isPostcode: boolean) {
  return useQuery({
    queryKey: ['merchants', merchantType, location, page, isPostcode],
    queryFn: async () => {
      // First check static data
      const normalizedMerchantType = merchantType.toLowerCase().replace(/\s+/g, '-');
      const normalizedLocation = location.toLowerCase().replace(/\s+/g, '-');
      
      const staticData = merchantData[normalizedMerchantType]?.[normalizedLocation];

      if (staticData) {
        return {
          results: staticData.results.slice((page - 1) * 10, page * 10),
          totalPages: Math.ceil(staticData.results.length / 10),
          totalResults: staticData.results.length
        };
      }

      // If no static data, fetch from API
      return searchMerchants(merchantType, location, page, isPostcode);
    },
    keepPreviousData: true,
    staleTime: 5 * 60 * 1000, // 5 minutes
    retry: 2,
    refetchOnWindowFocus: false,
    refetchOnMount: true
  });
}