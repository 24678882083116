import axios from 'axios';
import type { SearchResult } from '../types';
import { API_KEYS, API_ENDPOINTS } from '../constants';
import { getCoordinates, calculateDistance } from '../utils/geocoding';
import { getCachedResults, cacheResults } from '../cache';
import merchantData from '../data/merchants.json';

const RESULTS_PER_PAGE = 10;

export async function searchMerchants(
  merchantType: string,
  location: string,
  page = 1,
  isPostcode = false
): Promise<{ results: SearchResult[]; totalPages: number; totalResults: number }> {
  try {
    // Generate cache key
    const cacheKey = `${merchantType.toLowerCase()}-${location.toLowerCase()}`;
    
    // Check cache first
    const cachedResults = await getCachedResults(cacheKey);
    if (cachedResults) {
      const totalResults = cachedResults.length;
      const totalPages = Math.ceil(totalResults / RESULTS_PER_PAGE);
      const paginatedResults = cachedResults.slice(
        (page - 1) * RESULTS_PER_PAGE,
        page * RESULTS_PER_PAGE
      );
      return { results: paginatedResults, totalPages, totalResults };
    }

    // Check static data
    const normalizedMerchantType = merchantType.toLowerCase().replace(/\s+/g, '-');
    const normalizedLocation = location.toLowerCase().replace(/\s+/g, '-');
    const staticData = merchantData[normalizedMerchantType]?.[normalizedLocation];

    let results: SearchResult[] = staticData?.results || [];

    // Fetch from Google Places API if needed
    if (!staticData || results.length < 10) {
      try {
        const response = await axios.post(
          API_ENDPOINTS.PLACES_SEARCH,
          {
            textQuery: `${merchantType} in ${location}`,
            languageCode: 'en',
            maxResultCount: 50,
            rankPreference: 'DISTANCE',
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'X-Goog-Api-Key': API_KEYS.GOOGLE,
              'X-Goog-FieldMask': 'places.id,places.displayName,places.formattedAddress,places.rating,places.userRatingCount,places.websiteUri,places.internationalPhoneNumber,places.currentOpeningHours,places.location',
            },
          }
        );

        if (response.data.places) {
          const apiResults = response.data.places.map((place: any) => ({
            id: place.id,
            name: place.displayName.text,
            address: place.formattedAddress,
            rating: place.rating,
            totalRatings: place.userRatingCount,
            openNow: place.currentOpeningHours?.openNow,
            website: place.websiteUri,
            phoneNumber: place.internationalPhoneNumber,
            location: place.location
          }));

          // Merge API results with static data, removing duplicates
          const existingIds = new Set(results.map(r => r.id));
          apiResults.forEach(result => {
            if (!existingIds.has(result.id)) {
              results.push(result);
              existingIds.add(result.id);
            }
          });

          // Cache the combined results
          await cacheResults(cacheKey, results);
        }
      } catch (error) {
        console.error('Error fetching from Places API:', error);
      }
    }

    // Handle postcode-based distance sorting
    if (isPostcode && results.length > 0) {
      const userLocation = await getCoordinates(location);
      if (userLocation) {
        results = results.map(result => ({
          ...result,
          distance: calculateDistance(
            userLocation.lat,
            userLocation.lng,
            result.location.latitude,
            result.location.longitude
          )
        })).sort((a, b) => (a.distance || 0) - (b.distance || 0));
      }
    }

    const totalResults = results.length;
    const totalPages = Math.ceil(totalResults / RESULTS_PER_PAGE);
    const paginatedResults = results.slice(
      (page - 1) * RESULTS_PER_PAGE,
      page * RESULTS_PER_PAGE
    );

    return {
      results: paginatedResults,
      totalPages,
      totalResults
    };
  } catch (error) {
    console.error('Error searching merchants:', error);
    return {
      results: [],
      totalPages: 0,
      totalResults: 0
    };
  }
}