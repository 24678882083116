import React, { useState } from 'react';
import { Send } from 'lucide-react';
import { cities, merchants } from '../lib/data';
import { useEnquiry } from '../lib/hooks';
import type { Enquiry } from '../lib/types';

export function EnquiryForm() {
  const { mutate: submitEnquiry, isLoading, isSuccess } = useEnquiry();
  const [formData, setFormData] = useState<Omit<Enquiry, 'createdAt'>>({
    name: '',
    email: '',
    phone: '',
    message: '',
    merchantType: '',
    city: ''
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    submitEnquiry(formData, {
      onSuccess: () => {
        alert('Thank you for your enquiry. We will get back to you soon.');
        setFormData({
          name: '',
          email: '',
          phone: '',
          message: '',
          merchantType: '',
          city: ''
        });
      },
      onError: () => {
        alert('There was an error submitting your enquiry. Please try again.');
      }
    });
  };

  if (isSuccess) {
    return (
      <div className="text-center py-8">
        <p className="text-green-600 font-medium">Thank you for your enquiry. We will get back to you soon.</p>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Name
          </label>
          <input
            type="text"
            id="name"
            required
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
        </div>

        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Email
          </label>
          <input
            type="email"
            id="email"
            required
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
        </div>

        <div>
          <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
            Phone
          </label>
          <input
            type="tel"
            id="phone"
            value={formData.phone}
            onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
        </div>

        <div>
          <label htmlFor="merchantType" className="block text-sm font-medium text-gray-700">
            Merchant Type
          </label>
          <select
            id="merchantType"
            required
            value={formData.merchantType}
            onChange={(e) => setFormData({ ...formData, merchantType: e.target.value })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          >
            <option value="">Select Merchant Type</option>
            {merchants.map((merchant) => (
              <option key={merchant.slug} value={merchant.slug}>
                {merchant.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="city" className="block text-sm font-medium text-gray-700">
            City
          </label>
          <select
            id="city"
            required
            value={formData.city}
            onChange={(e) => setFormData({ ...formData, city: e.target.value })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          >
            <option value="">Select City</option>
            {cities.map((city) => (
              <option key={city.slug} value={city.slug}>
                {city.name}
              </option>
            ))}
          </select>
        </div>

        <div className="md:col-span-2">
          <label htmlFor="message" className="block text-sm font-medium text-gray-700">
            Message
          </label>
          <textarea
            id="message"
            required
            rows={4}
            value={formData.message}
            onChange={(e) => setFormData({ ...formData, message: e.target.value })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
        </div>
      </div>

      <button
        type="submit"
        disabled={isLoading}
        className="w-full flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        <Send className="w-5 h-5 mr-2" />
        {isLoading ? 'Submitting...' : 'Submit Enquiry'}
      </button>
    </form>
  );
}