import React from 'react';
import { Link } from 'react-router-dom';
import { Building2, Mail, Phone } from 'lucide-react';
import { merchants, cities } from '../lib/data';

export function Footer() {
  const popularCities = ['London', 'Manchester', 'Birmingham', 'Leeds', 'Liverpool']
    .map(name => cities.find(city => city.name === name))
    .filter(Boolean);

  return (
    <footer className="bg-gray-900 text-gray-300">
      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-8">
          {/* Company Info */}
          <div>
            <div className="flex items-center gap-2 mb-4">
              <Building2 className="w-6 h-6 text-blue-400" />
              <span className="font-bold text-white text-lg">MerchantFinder</span>
            </div>
            <p className="text-sm mb-4">
              Connecting you with trusted merchants across the UK. Find the right supplier for your project, compare prices, and read reviews.
            </p>
            <div className="space-y-2">
              <div className="flex items-center gap-2">
                <Mail className="w-4 h-4" />
                <a href="mailto:contact@merchantfinder.co.uk" className="text-sm hover:text-blue-400">
                  contact@merchantfinder.co.uk
                </a>
              </div>
              <div className="flex items-center gap-2">
                <Phone className="w-4 h-4" />
                <a href="tel:+441234567890" className="text-sm hover:text-blue-400">
                  +44 (0) 123 456 7890
                </a>
              </div>
            </div>
          </div>

          {/* Popular Categories */}
          <div>
            <h3 className="font-semibold text-white mb-4">Popular Categories</h3>
            <ul className="space-y-2">
              {merchants.map(merchant => (
                <li key={merchant.slug}>
                  <Link 
                    to={`/${merchant.slug}`}
                    className="text-sm hover:text-blue-400 transition-colors"
                  >
                    {merchant.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Popular Locations */}
          <div>
            <h3 className="font-semibold text-white mb-4">Popular Locations</h3>
            <ul className="space-y-2">
              {popularCities.map(city => city && (
                <li key={city.slug}>
                  <Link 
                    to={`/builders-merchants/${city.slug}`}
                    className="text-sm hover:text-blue-400 transition-colors"
                  >
                    Merchants in {city.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="font-semibold text-white mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/about" className="text-sm hover:text-blue-400 transition-colors">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/contact" className="text-sm hover:text-blue-400 transition-colors">
                  Contact
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy" className="text-sm hover:text-blue-400 transition-colors">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/terms-of-use" className="text-sm hover:text-blue-400 transition-colors">
                  Terms of Use
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="pt-8 border-t border-gray-800">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <p className="text-sm">
              © {new Date().getFullYear()} Buildiro Tech UK Ltd. All rights reserved.
            </p>
            <div className="flex items-center gap-6">
              <Link to="/privacy-policy" className="text-sm hover:text-blue-400 transition-colors">
                Privacy Policy
              </Link>
              <Link to="/terms-of-use" className="text-sm hover:text-blue-400 transition-colors">
                Terms of Use
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}