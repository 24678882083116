import React from 'react';
import { Link } from 'react-router-dom';
import { SEO } from '../components/SEO';
import { COMPANY_INFO } from '../lib/constants';

export function PrivacyPolicy() {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <SEO 
        title="Privacy Policy"
        description={`${COMPANY_INFO.NAME} privacy policy. Learn how we collect, use, and protect your personal information.`}
      />
      
      <div className="container mx-auto px-4 max-w-4xl">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Privacy Policy</h1>
        
        <div className="bg-white rounded-lg shadow-md p-8 space-y-6">
          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">1. Introduction</h2>
            <p className="text-gray-600">
              This Privacy Policy explains how {COMPANY_INFO.NAME} ("we," "our," or "us"), operating as MerchantFinder, 
              collects, uses, and protects your personal information. By using our website, you consent to the data practices 
              described in this policy.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">2. Information We Collect</h2>
            <div className="space-y-4">
              <p className="text-gray-600">We collect the following types of information:</p>
              <ul className="list-disc list-inside text-gray-600 space-y-2">
                <li>Personal information (name, email address, phone number)</li>
                <li>Location data when using our search features</li>
                <li>Usage data and analytics</li>
                <li>Cookies and similar technologies</li>
                <li>Enquiry form submissions</li>
              </ul>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">3. How We Use Your Information</h2>
            <div className="space-y-4">
              <p className="text-gray-600">We use your information to:</p>
              <ul className="list-disc list-inside text-gray-600 space-y-2">
                <li>Provide and improve our services</li>
                <li>Process your enquiries</li>
                <li>Send relevant communications</li>
                <li>Analyze and improve our website performance</li>
                <li>Comply with legal obligations</li>
              </ul>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">4. Contact Information</h2>
            <p className="text-gray-600">
              For privacy-related inquiries, please contact us at:<br />
              Email: {COMPANY_INFO.EMAIL}<br />
              Phone: {COMPANY_INFO.PHONE}<br />
              Address: {COMPANY_INFO.ADDRESS.STREET}, {COMPANY_INFO.ADDRESS.CITY}, {COMPANY_INFO.ADDRESS.POSTCODE}
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}