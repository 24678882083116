import React from 'react';
import { Building2 } from 'lucide-react';
import { SEO } from '../components/SEO';
import { SearchForm } from '../components/SearchForm';
import { PopularMerchants } from '../components/PopularMerchants';
import { generateHomeSEO } from '../lib/utils/seo';

export function HomePage() {
  const seo = generateHomeSEO();

  return (
    <div className="min-h-screen bg-gray-50">
      <SEO {...seo} />

      <div className="bg-gradient-to-r from-blue-600 to-blue-800 py-20">
        <div className="container mx-auto px-4">
          <div className="text-center mb-12">
            <Building2 className="w-16 h-16 text-white mx-auto mb-6" />
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-4">
              Find Local Merchants
            </h1>
            <p className="text-xl text-blue-100">
              Connect with trusted builders, timber, plumbers, roofing, and electrical merchants in your area
            </p>
          </div>
          <SearchForm />
        </div>
      </div>

      <div className="bg-gray-50">
        <PopularMerchants
          title="Popular Builders Merchants"
          merchantType="Builders Merchants"
          merchantSlug="builders-merchants"
        />
        
        <div className="border-t border-gray-200">
          <PopularMerchants
            title="Top 10 Electrical Merchants"
            merchantType="Electrical Merchants"
            merchantSlug="electrical-merchants"
          />
        </div>
      </div>
    </div>
  );
}