import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { cities, merchants } from '../lib/data';
import { ChevronRight } from 'lucide-react';

export function CategoriesPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="container mx-auto px-4">
        <div className="mb-8">
          <nav className="flex items-center text-sm text-gray-500 mb-4">
            <Link to="/" className="hover:text-blue-600">Home</Link>
            <ChevronRight className="w-4 h-4 mx-2" />
            <span className="text-gray-900">Categories</span>
          </nav>
          <h1 className="text-3xl font-bold text-gray-900">Merchant Categories</h1>
          <p className="text-gray-600 mt-2">Browse merchants by category and location across the UK</p>
        </div>

        {merchants.map((merchant) => (
          <div key={merchant.slug} className="mb-12">
            <h2 className="text-2xl font-semibold text-gray-900 mb-6">
              {merchant.name}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
              {cities.map((city) => (
                <Link
                  key={`${merchant.slug}-${city.slug}`}
                  to={`/${merchant.slug}/${city.slug}`}
                  className="bg-white rounded-lg shadow-sm p-4 hover:shadow-md transition-shadow"
                >
                  <h3 className="text-lg font-medium text-gray-900">
                    {merchant.name} in {city.name}
                  </h3>
                  <p className="text-sm text-gray-600 mt-1">
                    Find trusted {merchant.name.toLowerCase()} in {city.name}
                  </p>
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}