import type { SearchResult } from './types';

const CACHE_DURATION = 6 * 30 * 24 * 60 * 60 * 1000; // 6 months in milliseconds

export async function getCachedResults(query: string): Promise<SearchResult[] | null> {
  try {
    const response = await fetch('/api/cache', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ action: 'get', query }),
    });

    if (!response.ok) return null;
    const data = await response.json();
    return data.results;
  } catch (error) {
    console.error('Error getting cached results:', error);
    return null;
  }
}

export async function cacheResults(query: string, results: SearchResult[]): Promise<void> {
  try {
    await fetch('/api/cache', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ action: 'set', query, results }),
    });
  } catch (error) {
    console.error('Error caching results:', error);
  }
}