import React from 'react';
import { Mail, MapPin, Phone } from 'lucide-react';
import { SEO } from '../components/SEO';
import { COMPANY_INFO } from '../lib/constants';
import { EnquiryForm } from '../components/EnquiryForm';

export function ContactPage() {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <SEO 
        title="Contact Us"
        description="Get in touch with MerchantFinder. Contact our team for support, enquiries, or feedback."
      />
      
      <div className="container mx-auto px-4 max-w-4xl">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Contact Us</h1>
        
        <div className="bg-white rounded-lg shadow-md p-8 mb-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Get in Touch</h2>
              <div className="space-y-4">
                <div className="flex items-start gap-3">
                  <Mail className="w-5 h-5 text-blue-600 mt-1" />
                  <div>
                    <p className="font-medium text-gray-900">Email</p>
                    <a href={`mailto:${COMPANY_INFO.EMAIL}`} className="text-blue-600 hover:underline">
                      {COMPANY_INFO.EMAIL}
                    </a>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <Phone className="w-5 h-5 text-blue-600 mt-1" />
                  <div>
                    <p className="font-medium text-gray-900">Phone</p>
                    <a href={`tel:${COMPANY_INFO.PHONE}`} className="text-blue-600 hover:underline">
                      {COMPANY_INFO.PHONE}
                    </a>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <MapPin className="w-5 h-5 text-blue-600 mt-1" />
                  <div>
                    <p className="font-medium text-gray-900">Address</p>
                    <p className="text-gray-600">
                      {COMPANY_INFO.ADDRESS.STREET}<br />
                      {COMPANY_INFO.ADDRESS.CITY}, {COMPANY_INFO.ADDRESS.AREA}<br />
                      {COMPANY_INFO.ADDRESS.POSTCODE}<br />
                      {COMPANY_INFO.ADDRESS.COUNTRY}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Business Hours</h2>
              <dl className="space-y-2">
                <div className="flex justify-between">
                  <dt className="text-gray-600">Monday - Friday</dt>
                  <dd className="text-gray-900">9:00 AM - 6:00 PM</dd>
                </div>
                <div className="flex justify-between">
                  <dt className="text-gray-600">Saturday</dt>
                  <dd className="text-gray-900">10:00 AM - 4:00 PM</dd>
                </div>
                <div className="flex justify-between">
                  <dt className="text-gray-600">Sunday</dt>
                  <dd className="text-gray-900">Closed</dd>
                </div>
              </dl>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md p-8">
          <h2 className="text-xl font-semibold text-gray-900 mb-6">Send us a Message</h2>
          <EnquiryForm />
        </div>
      </div>
    </div>
  );
}