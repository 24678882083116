import React from 'react';

export function AboutPage() {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="container mx-auto px-4 max-w-3xl">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">About MerchantFinder</h1>
        <div className="bg-white rounded-lg shadow-md p-8">
          <p className="text-gray-600 mb-6">
            MerchantFinder is your trusted platform for discovering local building and trade merchants across the UK. 
            We help you connect with reliable builders merchants, timber merchants, plumbers merchants, roofing merchants, 
            and electrical merchants in your area.
          </p>
          <p className="text-gray-600 mb-6">
            Our mission is to simplify the process of finding quality trade suppliers, helping both professionals 
            and DIY enthusiasts access the materials and expertise they need for their projects.
          </p>
          <p className="text-gray-600">
            With comprehensive coverage across major UK cities and detailed merchant information, 
            we ensure you can make informed decisions about where to source your materials and supplies.
          </p>
        </div>
      </div>
    </div>
  );
}