import { cities, merchants } from '../data';

export function isValidUKPostcode(postcode: string): boolean {
  const regex = /^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/i;
  return regex.test(postcode);
}

export function isValidMerchant(slug: string): boolean {
  return merchants.some(m => m.slug === slug);
}

export function isValidCity(slug: string): boolean {
  return cities.some(c => c.slug === slug);
}

export function isValidPage(page: string): boolean {
  const pageNum = parseInt(page, 10);
  return !isNaN(pageNum) && pageNum > 0;
}

export function isStaticPage(slug: string): boolean {
  return ['about', 'contact', 'categories', 'privacy-policy', 'terms-of-use'].includes(slug);
}