import React from 'react';
import { MapPin, Star, Globe, Phone, Clock, Navigation2 } from 'lucide-react';
import type { SearchResult } from '../lib/types';

interface ResultsListProps {
  results: SearchResult[];
  loading: boolean;
}

export function ResultsList({ results, loading }: ResultsListProps) {
  const openGoogleMaps = (result: SearchResult) => {
    const query = encodeURIComponent(result.address);
    const url = `https://www.google.com/maps/dir/?api=1&destination=${query}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  if (loading) {
    return (
      <div className="w-full h-64 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (!results.length) {
    return (
      <div className="text-center py-12">
        <p className="text-gray-600">No merchants found. Try adjusting your search criteria.</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {results.map((result) => (
        <div
          key={result.id}
          className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow"
        >
          <h3 className="text-xl font-semibold mb-2">{result.name}</h3>
          
          <div className="space-y-2">
            <div className="flex items-start gap-2">
              <MapPin className="w-5 h-5 text-gray-500 mt-1 flex-shrink-0" />
              <div>
                <p className="text-gray-600">{result.address}</p>
                {result.distance !== undefined && (
                  <p className="text-sm text-blue-600 mt-1">
                    {result.distance} km away
                  </p>
                )}
              </div>
            </div>

            {result.rating && (
              <div className="flex items-center gap-2">
                <Star className="w-5 h-5 text-yellow-400" />
                <span className="text-gray-700">
                  {result.rating.toFixed(1)} ({result.totalRatings} reviews)
                </span>
              </div>
            )}

            {result.website && (
              <div className="flex items-center gap-2">
                <Globe className="w-5 h-5 text-gray-500" />
                <a
                  href={result.website}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:underline"
                >
                  Visit Website
                </a>
              </div>
            )}

            {result.phoneNumber && (
              <div className="flex items-center gap-2">
                <Phone className="w-5 h-5 text-gray-500" />
                <a
                  href={`tel:${result.phoneNumber}`}
                  className="text-blue-600 hover:underline"
                >
                  {result.phoneNumber}
                </a>
              </div>
            )}

            {result.openNow !== undefined && (
              <div className="flex items-center gap-2">
                <Clock className="w-5 h-5 text-gray-500" />
                <span className={result.openNow ? 'text-green-600' : 'text-red-600'}>
                  {result.openNow ? 'Open Now' : 'Closed'}
                </span>
              </div>
            )}

            <button
              onClick={() => openGoogleMaps(result)}
              className="mt-4 flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              <Navigation2 className="w-4 h-4" />
              Navigate
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}