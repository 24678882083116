import axios from 'axios';
import { API_KEYS } from '../constants';

interface Location {
  lat: number;
  lng: number;
}

// Validate UK postcode format
function isValidUKPostcode(postcode: string): boolean {
  const regex = /^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/i;
  return regex.test(postcode);
}

// Format postcode to standard format (e.g., "N1 9AB")
function formatPostcode(postcode: string): string {
  const clean = postcode.toUpperCase().replace(/\s+/g, '');
  const length = clean.length;
  return `${clean.slice(0, length - 3)} ${clean.slice(length - 3)}`;
}

export async function getCoordinates(postcode: string): Promise<Location | null> {
  try {
    if (!isValidUKPostcode(postcode)) {
      console.warn('Invalid UK postcode format:', postcode);
      return null;
    }

    const formattedPostcode = formatPostcode(postcode);
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json`,
      {
        params: {
          address: `${formattedPostcode}, UK`,
          key: API_KEYS.GOOGLE,
          region: 'uk'
        }
      }
    );

    if (response.data.status === 'OK' && response.data.results.length > 0) {
      const { lat, lng } = response.data.results[0].geometry.location;
      return { lat, lng };
    }

    console.warn('No location found for postcode:', formattedPostcode);
    return null;
  } catch (error) {
    console.error('Error geocoding postcode:', error);
    return null;
  }
}

export function calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
  const R = 6371; // Earth's radius in kilometers
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  
  const a = 
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * 
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return Math.round((R * c) * 10) / 10; // Round to 1 decimal place
}

function toRad(degrees: number): number {
  return degrees * (Math.PI / 180);
}