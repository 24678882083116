import { isValidUKPostcode, isValidMerchant, isValidCity, isValidPage, isStaticPage } from './validators';

export function validateRoute(pathname: string): boolean {
  const [, merchantSlug, locationSlug, page] = pathname.split('/');

  // Handle root path
  if (!merchantSlug) return true;

  // Handle static pages
  if (isStaticPage(merchantSlug)) return true;

  // Validate merchant type
  if (!isValidMerchant(merchantSlug)) return false;

  // If no location slug, it's a valid merchant category page
  if (!locationSlug) return true;

  // Check if it's a valid postcode or city
  if (!isValidUKPostcode(locationSlug) && !isValidCity(locationSlug)) return false;

  // Validate page number if present
  if (page && !isValidPage(page)) return false;

  return true;
}