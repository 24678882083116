import React from 'react';
import { useLocation } from 'react-router-dom';
import type { City, Merchant } from '../types';
import { cities, merchants } from '../data';

interface RouteMatch {
  merchant?: Merchant;
  city?: City;
  page?: number;
}

export function useRouteMatch(): RouteMatch {
  const location = useLocation();
  const [, merchantSlug, citySlug, page] = location.pathname.split('/');

  const merchant = merchants.find(m => m.slug === merchantSlug);
  const city = citySlug ? 
    cities.find(c => c.slug === citySlug) || 
    (isValidUKPostcode(citySlug) ? { name: citySlug.toUpperCase(), slug: citySlug.toLowerCase() } : undefined) : 
    undefined;
  
  return {
    merchant,
    city,
    page: page ? parseInt(page, 10) : undefined
  };
}

export function validateRoute(pathname: string): boolean {
  const [, merchantSlug, citySlug, page] = pathname.split('/');

  // Handle root and static pages
  if (!merchantSlug || ['about', 'contact', 'categories', 'privacy-policy', 'terms-of-use'].includes(merchantSlug)) {
    return true;
  }

  // Validate merchant type
  const merchant = merchants.find(m => m.slug === merchantSlug);
  if (!merchant) return false;

  // If no city slug, it's a valid merchant category page
  if (!citySlug) return true;

  // Check if it's a valid postcode or city
  const isValidCity = cities.some(c => c.slug === citySlug);
  const isValidPostcode = isValidUKPostcode(citySlug);
  
  if (!isValidCity && !isValidPostcode) return false;

  // Validate page number if present
  if (page) {
    const pageNum = parseInt(page, 10);
    if (isNaN(pageNum) || pageNum < 1) return false;
  }

  return true;
}

function isValidUKPostcode(postcode: string): boolean {
  return /^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/i.test(postcode);
}