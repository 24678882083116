import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';
import { ResultsList } from '../components/ResultsList';
import { Pagination } from '../components/Pagination';
import { SponsoredAd } from '../components/SponsoredAd';
import { SEO } from '../components/SEO';
import { useRouteMatch } from '../lib/router/StaticRouter';
import { useSearch } from '../lib/hooks';
import { generateSearchResultsSEO } from '../lib/utils/seo';

export function SearchResults() {
  const navigate = useNavigate();
  const { merchant, city, page = 1 } = useRouteMatch();
  
  if (!merchant || !city) {
    return null; // RouteValidator will handle the redirect
  }

  const location = city.slug;
  const isPostcode = /^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/i.test(location);
  const { data, isLoading } = useSearch(merchant.name, city.name, page, isPostcode);
  const seo = generateSearchResultsSEO(merchant, city, page);

  const handlePageChange = (newPage: number) => {
    if (newPage === 1) {
      navigate(`/${merchant.slug}/${location}`);
    } else {
      navigate(`/${merchant.slug}/${location}/${newPage}`);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <SEO {...seo} />

      <div className="container mx-auto px-4">
        <SponsoredAd />

        <div className="mb-8">
          <nav className="flex flex-wrap items-center text-sm text-gray-500 mb-4">
            <Link to="/" className="hover:text-blue-600">Home</Link>
            <ChevronRight className="w-4 h-4 mx-2" />
            <Link to={`/${merchant.slug}`} className="hover:text-blue-600">{merchant.name}</Link>
            <ChevronRight className="w-4 h-4 mx-2" />
            <span className="text-gray-900">{city.name}</span>
            {page > 1 && (
              <>
                <ChevronRight className="w-4 h-4 mx-2" />
                <span className="text-gray-900">Page {page}</span>
              </>
            )}
          </nav>

          <h1 className="text-2xl md:text-3xl font-bold text-gray-900">
            {merchant.name} in {city.name}{page > 1 ? ` - Page ${page}` : ''}
          </h1>
          <p className="text-gray-600 mt-2">
            Find the best {merchant.name.toLowerCase()} in {city.name}
          </p>
          {data?.totalResults ? (
            <p className="text-sm text-gray-500 mt-1">
              Showing {((page - 1) * 10) + 1}-{Math.min(page * 10, data.totalResults)} of {data.totalResults} results
            </p>
          ) : null}
        </div>

        <ResultsList results={data?.results || []} loading={isLoading} />

        {data?.totalPages && data.totalPages > 1 && (
          <Pagination
            currentPage={page}
            totalPages={data.totalPages}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
}