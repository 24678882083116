import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { HomePage } from './pages/HomePage';
import { CategoriesPage } from './pages/CategoriesPage';
import { AboutPage } from './pages/AboutPage';
import { ContactPage } from './pages/ContactPage';
import { SearchResults } from './pages/SearchResults';
import { MerchantCategoryPage } from './pages/MerchantCategoryPage';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { TermsOfUse } from './pages/TermsOfUse';
import { NotFound } from './pages/NotFound';
import { validateRoute } from './lib/router';

function RouteValidator({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const isValidRoute = validateRoute(location.pathname);

  if (!isValidRoute) {
    return <Navigate to="/404" replace />;
  }

  return <>{children}</>;
}

export default function App() {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-1">
        <RouteValidator>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/categories" element={<CategoriesPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/:merchantType" element={<MerchantCategoryPage />} />
            <Route path="/:merchantType/:location" element={<SearchResults />} />
            <Route path="/:merchantType/:location/:page" element={<SearchResults />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </RouteValidator>
      </main>
      <Footer />
    </div>
  );
}