import React from 'react';
import { Star } from 'lucide-react';

export function SponsoredAd() {
  return (
    <div className="bg-blue-600 rounded-lg shadow-md p-6 mb-8 relative overflow-hidden text-white">
      <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between gap-6">
        {/* Left Content */}
        <div className="flex-1">
          <div className="flex items-start justify-between mb-4">
            <h2 className="text-xl md:text-2xl font-bold pr-20">
              New Platform for Tradespeople: ScrewHero
            </h2>
            <div className="absolute top-6 right-6 flex flex-col items-end">
              <span className="bg-blue-500 text-white text-xs px-2 py-1 rounded mb-2">
                Sponsored
              </span>
              <div className="flex items-center">
                <Star className="w-5 h-5 text-yellow-400 fill-current" />
                <span className="ml-1 font-semibold text-white">4.8</span>
              </div>
            </div>
          </div>

          <h3 className="text-lg md:text-xl font-semibold text-blue-100 mb-4">
            Grow Your Business with ScrewHero!
          </h3>

          <ul className="space-y-2 mb-6">
            <li className="flex items-start text-sm md:text-base text-blue-50">
              • Join thousands of UK tradespeople building their business
            </li>
            <li className="flex items-start text-sm md:text-base text-blue-50">
              • Showcase your skills with a professional profile
            </li>
            <li className="flex items-start text-sm md:text-base text-blue-50">
              • Connect with other professionals and share insights
            </li>
          </ul>

          <div className="space-y-2 mb-6">
            <p className="flex items-center text-sm md:text-base text-blue-50">
              💼 Special Offer: 80% off your first subscription!
            </p>
            <p className="flex items-center text-sm md:text-base text-blue-50">
              📈 Your next job and a thriving community await!
            </p>
          </div>
        </div>

        {/* Right Content - CTA Button */}
        <div className="flex justify-start lg:justify-end">
          <a
            href="https://screwhero.com"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-white text-blue-600 px-6 py-3 rounded-lg font-semibold hover:bg-blue-50 transition-colors text-center min-w-[200px]"
          >
            Boost Your Business
          </a>
        </div>
      </div>
    </div>
  );
}