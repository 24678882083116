export const API_KEYS = {
  GOOGLE: 'AIzaSyDpOyUOhYwaXQfaLnu5OtcrY4PKdyC9wzo'
};

export const PAGINATION = {
  RESULTS_PER_PAGE: 10
};

export const API_ENDPOINTS = {
  PLACES_SEARCH: 'https://places.googleapis.com/v1/places:searchText'
};

export const COMPANY_INFO = {
  NAME: 'Buildiro Tech UK Ltd',
  PHONE: '+44 (0) 7865007296',
  EMAIL: 'contact@merchantfinder.co.uk',
  ADDRESS: {
    STREET: '34b York Way',
    CITY: 'London',
    AREA: 'King\'s Cross',
    POSTCODE: 'N1 9AB',
    COUNTRY: 'United Kingdom'
  }
};