import { COMPANY_INFO } from '../constants';
import type { City, Merchant } from '../types';

interface SEOData {
  title: string;
  description: string;
  canonical?: string;
  schema?: Record<string, any>;
}

export function generateHomeSEO(): SEOData {
  return {
    title: 'Find Local Building & Trade Merchants | MerchantFinder',
    description: `Compare trusted builders merchants, timber merchants, plumbers merchants, and more across the UK. Find local suppliers with ${COMPANY_INFO.NAME}.`,
    canonical: 'https://merchantfinder.co.uk',
    schema: {
      "@type": "Organization",
      "name": COMPANY_INFO.NAME,
      "url": "https://merchantfinder.co.uk",
      "logo": "https://merchantfinder.co.uk/logo.png",
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": COMPANY_INFO.PHONE,
        "contactType": "customer service"
      }
    }
  };
}

export function generateSearchResultsSEO(merchant: Merchant, city: City, page?: number): SEOData {
  const pageTitle = page && page > 1 ? ` - Page ${page}` : '';
  const baseTitle = `${merchant.name} in ${city.name}${pageTitle}`;
  const canonical = `https://merchantfinder.co.uk/${merchant.slug}/${city.slug}${page ? `/${page}` : ''}`;
  
  return {
    title: `${baseTitle} | Top Local ${merchant.name}`,
    description: `Find the best ${merchant.name.toLowerCase()} in ${city.name}. Compare ratings, reviews, and contact details for local ${merchant.name.toLowerCase()}. Get directions and opening hours.`,
    canonical,
    schema: {
      "@type": "ItemList",
      "itemListElement": [
        {
          "@type": "LocalBusiness",
          "name": `${merchant.name} in ${city.name}`,
          "description": `Directory of ${merchant.name.toLowerCase()} in ${city.name}`,
          "url": canonical
        }
      ]
    }
  };
}